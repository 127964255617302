/* Set box-sizing to border-box for easier layout calculations */
*,
*::before,
*::after {
    box-sizing: border-box;
}

.monitoring-table {
    width: 100%;
    border-collapse: separate;
    margin-top: 10px;
    border-spacing: 0;
    border: 2px solid #ccc;
    /* Border color and width for the table */
    border-radius: 10px;
    /* Rounded corners for the table */
    overflow: hidden;
    /* Prevents the border-radius from being cut off */
}

.monitoring-page h1 {
    font-size: 1.5rem;
    /* Adjust the font size */
    color: #333;
    /* Set the text color */
}



.monitoring-table th {
    font-size: 0.85rem;
    /* Decrease font size for header */
    font-weight: 600;
    padding: 8px;
    text-align: left;

}

.monitoring-table td {
    font-size: 0.85rem;
    /* Decrease font size for table row values */
    padding: 8px;
    text-align: left;
    min-height: 30px;
}

/* Optional: if you need to target specific rows with different height */
.custom-row-height {
    height: 25px;
    /* Adjust specific row height as needed */
    font-size: 0.9rem;
}

/* Grey hover effect on table cells with a transition */
.monitoring-table tbody tr:hover td {
    background-color: #e4e1e1;
    transition: background-color 0.3s ease;
}

.column-header {
    background-color: white;
    /* Background color for the header */
    padding: 10px;
    /* Padding for spacing */
    font-weight: bold;
    /* Makes the header text bold */
    text-align: center;
    /* Center align text */

}


.header-row th {
    background-color: white;
    /* White background for header */
    font-weight: bold;
    /* Bold font for better visibility */

}



.row-grey {
    background-color: #f1f1f1;
}

.row-white {
    background-color: #ffffff;
}

.pagination {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    gap: 5px;
}

.pagination button {
    padding: 5px 10px;
    border: 1px solid #ddd;
    background-color: #f4f4f4;
    cursor: pointer;
    border: 1px solid #ccc;
    /* Light color border */
    border-radius: 5px;
    /* Rounded corners */
    transition: background-color 0.2s;
    /* Smooth transition for hover */
}

.pagination button:hover:not(:disabled) {
    background: linear-gradient(135deg, #818eff, #ff6e3e);
    /* Add gradient background */
    -webkit-background-clip: text;
    /* Clip the background to the text */
    -webkit-text-fill-color: transparent;
    /* Make the text color transparent */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    /* Add shadow for depth */
}

.pagination button:disabled {
    cursor: not-allowed;
    background-color: #e0e0e0;
}

.pagination span {
    margin: 5px 5px 5px 5px;
    font-weight: 300;
    background-color: #414040;
    /* background: linear-gradient(135deg, #818eff, #ff6e3e); */
    /* Add gradient background */
    -webkit-background-clip: text;
    /* Clip the background to the text */
    -webkit-text-fill-color: transparent;
    /* Make the text color transparent */
}

.table-top-right {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* Align items to the top */
    flex-wrap: wrap;
    /* Allow wrapping to place the selected range below */
}

.time-range-selector {
    margin-right: auto;
    /* Align to the left */
    flex-direction: column;
    /* Stack the dropdown and selected range */
    margin-right: 15px;
}

.left-align {
    display: flex;
    align-items: flex-start;
}

.time-range-container {
    display: flex;
    align-items: center;
    gap: 8px;
    /* Controls spacing between the time selector and custom date range */
}

.page-size-selector {
    margin-left: auto;
    margin-right: 15px;
    /* Align to the right */
}

.page-size-selector select {
    padding: 4px 8px;
    /* Reduce padding for smaller size */
    border: 1px solid #ccc;
    /* Light color border */
    border-radius: 5px;
    /* Rounded corners */
    font-size: 0.9rem;
    /* Decrease font size */
    width: 65px;
    /* Adjust width to fit content */
    outline: none;
    /* Remove outline on focus */
    transition: border-color 0.3s ease;
    /* Smooth border transition */
    cursor: pointer;
}

.time-range-selector select {
    padding: 4px 8px;
    /* Reduce padding for smaller size */
    border: 1px solid #ccc;
    /* Light color border */
    border-radius: 5px;
    /* Rounded corners */
    font-size: 0.9rem;
    /* Decrease font size */
    width: 180px;
    /* Adjust width to fit content */
    outline: none;
    /* Remove outline on focus */
    transition: border-color 0.3s ease;
    /* Smooth border transition */
    cursor: pointer;
}


/* Change border color on focus */



/* Change border color on focus */
.time-range-selector select:hover,
.page-size-selector select:hover {
    border-color: #007BFF;
    /* Change border color on hover */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    /* Add shadow on hover */
}

/* range display in label */
.selected-range p {
    font-size: 0.9rem;
    /* Adjust this value as needed */
    color: #555;
    /* Optional: a slightly muted color */
    margin-top: 5px;
    margin-bottom: 10px;
    /* Optional: adds a bit of space from the dropdown */
}

/* Filter dropdown */

.filter-dropdowns {
    display: flex;
    gap: 15px;

}



/* Optional: Align filters and headers properly */

.filter-row th {
    vertical-align: top;
    /* Align filter dropdowns to the top */
}

.dropdown-filter {
    width: 100%;
    height: auto;
    font-size: 1rem;
    /* Full width to match column size */
    border: 1px solid #ccc;
    /* Light color border */
    border-radius: 5px;
    /* Rounded corners */
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    /* Smooth transition for background and shadow */
}

/* Halo effect on hover */
.dropdown-filter:hover {
    border-color: #007BFF;
    /* Change border color on hover */
    box-shadow: 0 0 6px rgba(0, 123, 255, 0.5);
    /* Add shadow on hover */
    cursor: pointer;
}

/* Wrapper and Input Container */
.customsearch-wrapper {
    display: flex;
    align-items: center;
    /* Vertically align search and sort icons */
    gap: 0.5rem;
    /* Space between input and sort icon */
}

.customsearch-input-container {
    position: relative;
    /* For positioning the dropdown */
    display: flex;
    align-items: center;
    /* Align input and icon */
    border: 1px solid #d2cee6;
    border-radius: 6px;
    padding: 0.3rem 0.5rem;
    background-color: #fff;
    width: 100%;
    height: 30px;
}

/* Search Icon */
.customsearch-icon {
    color: #6c757d;
    margin-right: 0.5rem;
    font-size: 1rem;
}

/* Input Field */
.customsearch-input {
    border: none;
    outline: none;
    flex: 1;
    font-size: 0.8rem;
    background-color: transparent;
    width: 100%;
    font-weight: 200;
}

/* .customsearch-container {
    width: 100%;
} */


/* Sort Icon */
.custom-sort-icon {
    color: #6c757d;
    font-size: 1.2rem;
    cursor: pointer;
}

.custom-sort-icon:hover {
    color: #0d6efd;
    /* Blue hover effect */
}

.input-wrapper {
    border: 1px solid #ccc;
    /* Light gray border */
    border-radius: 0.375rem;
    /* Bootstrap rounded corners */
    background-color: #fff;
    /* White background */
    position: relative;
    padding: 0.5rem;
    /* Adjust padding */
    display: inline-block;
    width: 100%;
}

.custom-calendar-input {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.custom-calendar-input input {
    border: none;
    box-shadow: none;
    font-size: 0.80rem;
    /* Decrease font size */
    padding: 0.18rem;
    /* Decrease padding */
    width: 100%;
    cursor: pointer;
    /* Ensure input takes full width */
}


.custom-calendar-icon {
    cursor: pointer;
    font-size: 1rem;
    /* Icon size */
    color: #666;
    /* Icon color */
    padding-right: 5px;
    cursor: pointer;
}

.custom-calendar-icon:hover {
    cursor: pointer;
    color: #007bff;
}

.custom-datepicker-wrapper {
    z-index: 1050;
    /* Ensure the DatePicker window appears above other elements */
    width: 100%;
    /* Set a specific width if needed, or adjust as per your layout */
}

.custom-calendar-datepicker {
    border-radius: 5px;
    /* Rounded corners */

    /* Subtle shadow for depth */
    background-color: #fff;
    /* White background */
    padding: 1px;
    /* Add some padding for better spacing */
}

.custom-calendar-input-field {

    border: none;
    /* Remove border */
    outline: none;
    /* Remove focus outline */
    box-shadow: none;
    /* Optional: Remove shadow on focus */
    font-weight: 200;
    cursor: pointer;
}

.custom-calendar-wrapper {
    position: relative;
    display: inline-block;
}

.custom-calendar-divtag {
    border: 1px solid #ccc;
    cursor: pointer;
    margin-bottom: 10px;
}

.custom-calendar-divtag:hover {
    cursor: pointer;
    border: 1px solid #007bff;
    /* Blue border */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    /* Light blue shadow */
}