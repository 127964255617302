.search-editmessage-container {
    position: relative;
    /* This will help us position the icon inside the input */
    width: 100%;
}



.search-editmessage-icon {
    position: absolute;
    /* Position the icon inside the input */
    right: 10px;
    /* Space it to the right */
    top: 50%;
    /* Center the icon vertically */
    transform: translateY(-50%);
    /* Fine-tune vertical centering */
    cursor: pointer;
    font-size: 18px;
    /* Set size of the icon */
    color: #555;
    /* Change color if needed */
}

/* General input box styling */
.search-editmessage-input {
    width: 100%;
    padding: 10px;
    font-size: 15px;
    height: 35px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #f7f7f7;
    color: #555;
    transition: all 0.3s ease;
    /* Smooth transition for hover and focus */
}

/* Hover effect */
.search-editmessage-input:hover {
    box-shadow: 0px 0px 8px rgba(0, 123, 255, 0.4);
    /* Blue shadow on hover */
}

/* Focus effect */
.search-editmessage-input:focus {
    border: 1px solid #007BFF;
    /* Blue border on focus */
    outline: none;
    /* Remove the default outline on focus */
}