@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900;1000&family=Roboto:wght@100;300;400;500;700;900&display=swap');

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
.dashboard-layout :root {
    scroll-behavior: smooth;
    --primary: #4154f1;
    --second: #717ff5;
    --bgColor: #f6f9ff;
}

.dashboard-layout body {
    font-family: 'Open Sans', sans-serif;
    color: #444444;
    background: var(--bgColor);
}

.dashboard-layout a {
    color: #4154f1;
    text-decoration: none;
    cursor: pointer;
}

.dashboard-layout a:hover {
    color: #717ff5;
    text-decoration: none;
}

.dashboard-layout h1,
.dashboard-layout h2,
.dashboard-layout h3,
.dashboard-layout h4,
.dashboard-layout h5,
.dashboard-layout h6 {
    font-family: 'Nunito', sans-serif;
}

/*--------------------------------------------------------------
# Override some default Bootstrap stylings
--------------------------------------------------------------*/
/* Dropdown menus */
.dashboard-dropdown-menu {
    border-radius: 4px;
    padding: 10px 0;
    -webkit-animation-name: dropdown-animate;
    animation-name: dropdown-animate;
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    border: 0;
    box-shadow: 0 5px 30px 0 rgba(82, 63, 105, 0.2);
}

.dashboard-dropdown-menu .dashboard-dropdown-header,
.dashboard-dropdown-menu .dashboard-dropdown-footer {
    text-align: center;
    font-size: 15px;
    padding: 10px 25px;
}

.dashboard-dropdown-menu .dashboard-dropdown-footer a {
    color: #444444;
    text-decoration: underline;
}

.dashboard-dropdown-menu .dashboard-dropdown-footer a:hover {
    text-decoration: none;
}

.dashboard-dropdown-menu .dashboard-dropdown-divider {
    color: #a5c5fe;
    margin: 0;
}

.dashboard-dropdown-menu .dashboard-dropdown-item {
    font-size: 14px;
    padding: 10px 15px;
    transition: 0.3s;
}

.dashboard-dropdown-menu .dashboard-dropdown-item i {
    margin-right: 10px;
    font-size: 18px;
    line-height: 0;
}

.dashboard-dropdown-menu .dashboard-dropdown-item:hover {
    background-color: #f6f9ff;
}

@media (min-width: 768px) {
    .dashboard-dropdown-menu-arrow::before {
        content: '';
        width: 13px;
        height: 13px;
        background: #fff;
        position: absolute;
        top: -7px;
        right: 20px;
        transform: rotate(45deg);
        border-top: 1px solid #eaedf1;
        border-left: 1px solid #eaedf1;
    }
}

@-webkit-keyframes dropdown-animate {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }

    0% {
        opacity: 0;
    }
}

@keyframes dropdown-animate {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }

    0% {
        opacity: 0;
    }
}

/* Light Backgrounds */
.bg-primary-light {
    background-color: #cfe2ff;
    border-color: #cfe2ff;
}

.bg-secondary-light {
    background-color: #e2e3e5;
    border-color: #e2e3e5;
}

.bg-success-light {
    background-color: #d1e7dd;
    border-color: #d1e7dd;
}

.bg-danger-light {
    background-color: #f8d7da;
    border-color: #f8d7da;
}

.bg-warning-light {
    background-color: #fff3cd;
    border-color: #fff3cd;
}

.bg-info-light {
    background-color: #cff4fc;
    border-color: #cff4fc;
}

.bg-dark-light {
    background-color: #d3d3d4;
    border-color: #d3d3d4;
}

/* Card */
.dashboard-card {
    margin-bottom: 30px;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.dashboard-card-header,
.dashboard-card-footer {
    border-color: #ebeef4;
    background-color: #fff;
    color: #798eb3;
    padding: 15px;
}

.dashboard-card-title {
    padding: 20px 0 15px 0;
    font-size: 18px;
    font-weight: 500;
    color: #012970;
    letter-spacing: 1px;
    font-family: 'Roboto', sans-serif;
}

.dashboard-card-title span {
    color: #899bbd;
    font-size: 14px;
    font-weight: 400;
}

.dashboard-card-body {
    padding: 0 20px 20px 20px;
}

.dashboard-card-img-overlay {
    background-color: rgba(255, 255, 255, 0.6);
}

/* Alerts */
.alert-heading {
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
}

/* Close Button */
.btn-close {
    background-size: 25%;
}

.btn-close:focus {
    outline: 0;
    box-shadow: none;
}

/* Accordion */
.accordion-item {
    border: 1px solid #ebeef4;
}

.accordion-button:focus {
    outline: 0;
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    color: #012970;
    background-color: #f6f9ff;
}

.accordion-flush .accordion-button {
    padding: 15px 0;
    background: none;
    border: 0;
}

.accordion-flush .accordion-button:not(.collapsed) {
    box-shadow: none;
    color: #4154f1;
}

.accordion-flush .accordion-body {
    padding: 0 0 15px 0;
    color: #3e4f6f;
    font-size: 15px;
}

/* Breadcrumbs */
.breadcrumb {
    font-size: 14px;
    font-family: 'Nunito', sans-serif;
    color: #899bbd;
    font-weight: 600;
}

.breadcrumb a {
    color: #899bbd;
    transition: 0.3s;
}

.breadcrumb a:hover {
    color: #51678f;
}

.breadcrumb .breadcrumb-item::before {
    color: #899bbd;
}

.breadcrumb .active {
    color: #51678f;
    font-weight: 600;
}

/* Bordered Tabs */
.nav-tabs-bordered {
    border-bottom: 2px solid #ebeef4;
}

.nav-tabs-bordered .nav-link {
    margin-bottom: -2px;
    border: none;
    color: #2c384e;
}

.nav-tabs-bordered .nav-link:hover,
.nav-tabs-bordered .nav-link:focus {
    color: #4154f1;
}

.nav-tabs-bordered .nav-link.active {
    background-color: #fff;
    color: #4154f1;
    border-bottom: 2px solid #4154f1;
}