/* Overall Login Page Container */
.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(to right, #e0f7fa, #b3e5fc);
    /* Light gradient background */
    margin: 0;
}

/* Login Card */
.loginpage-card {
    display: flex;
    width: 80%;
    max-width: 900px;
    height: 60%;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Box shadow for depth */
    background-color: #fff;
}

/* Left Section */
.loginpage-card-left {
    background-color: #f0f0f0;
    width: 45%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

/* Logo in Top Left Corner */
.logo {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 120px;
}

/* Full-Screen Left Image */
.loginpage-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Right Section */
.loginpage-card-right {
    width: 54%;
    padding: 40px;

}

.loginpage-title {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
    background: linear-gradient(35deg, #818eff, #ff6e3e);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    /* Ensure the gradient covers only the text */
}

.custom-input-group {
    margin-bottom: 1.5rem;
    position: relative;
}


.loginpage-input-group input {
    width: 100%;
    padding: 1.2rem;
    /* Increased padding to make input box taller */
    /* Ensures Bootstrap padding is overridden */
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 1rem;
}

/* Form Controls */
.form-group label {
    display: block;
    font-size: 0.875rem;
    color: #8a8787;
    margin-bottom: 0.5rem;
}

.form-control {
    padding-right: 80px;
}



.show-hide-btn {
    position: absolute;
    top: 50%;
    right: 10px;
    /* Adjust this value to position the button */
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
}

.show-hide-btn img {
    width: 25px;
    height: auto;
    display: block;
    /* Ensure image visibility */
}

.password-wrapper {
    position: relative;
    width: 100%;
    /* This helps position the eye button inside this wrapper */
}

/* Gradient Login Button */
.login-btn-onpage {
    background: linear-gradient(105deg, #818eff, #ff6e3e);
    color: #fff;
    border: none;
    padding: 0.55rem 2rem;
    /* Increased padding for better alignment */
    border-radius: 25px;
    text-decoration: none;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.3s ease, transform 0.3s ease;
    margin-bottom: 3.5rem;
    /* Reduced margin-top to decrease space */
    /* Add top margin to space the button away from the input */
}

.login-btn-onpage:hover {
    background: linear-gradient(105deg, #ff6e3e, #818eff);
    transform: scale(1.05);
    text-decoration: none;
    color: #fff;
}

.login-btn-onpage:disabled {
    background: #ebc1c1;
    /* Change background color */
    cursor: not-allowed;
    /* Change cursor to indicate it's disabled */
    opacity: 0.6;
    /* Make it appear faded */
    transform: none;
    /* Remove transform effects */
}

.loginpage-btn-container {
    display: flex;
    justify-content: flex-end;
}

/* Register Link Section */
.register-link {
    margin-top: 1.2rem;
    justify-content: right;
}

.register-link p {
    font-size: 0.80rem;
    color: #555;
    justify-content: right;
}

.register-link a {
    color: #1e90ff;
    text-decoration: none;
    font-weight: 500;
    border-radius: 5px;
}

.register-link a:hover {
    text-decoration: none;
    background: linear-gradient(15deg, #818eff, #ff6e3e);
    background-clip: text;
    color: transparent;
}

/* Responsive styles */
@media (max-width: 768px) {
    .loginpage-card {
        flex-direction: column;
        width: 90%;
    }

    .loginpage-card-left,
    .loginpage-card-right {
        width: 100%;
    }

    .loginpage-image {
        display: none;
    }
}