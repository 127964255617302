/*--------------------------------------------------------------
# Main
--------------------------------------------------------------*/
#main {
  margin-top: 40px;
  padding: 20px 40px;
  transition: all 0.3s;
  background-color: #fff;
}

@media (max-width: 1199px) {
  #main {
    padding: 20px;
  }
}