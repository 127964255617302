/* General styles for the hero section */
.hero {
    align-items: center;
    justify-content: center;
    /* Cover the whole screen */
    background: radial-gradient(circle at left center, #e0f7fa 40%, #b3e5fc 70%, #97cee8 100%);
    /* Gradient background */
}

.hero-content {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.hero-text {
    flex: 0 0 60%;
    /* Take up 60% of the width */
    padding: 2rem 4rem 1rem 4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Align content to the left */
    justify-content: center;
}

.hero-text h1,
.hero-text h2,
.hero-btn {
    text-align: left;
    /* Ensure text is left-aligned */
}

.hero-text h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    font-weight: 800;
    /* background: linear-gradient(135deg, rgba(128, 0, 128, 0.6), rgba(27, 154, 238, 0.8), rgba(128, 0, 128, 0.6)); */
    background: linear-gradient(135deg, #818eff, #ff6e3e, #818eff);
    background-clip: text;
    color: transparent;
}

.hero-text h2 {
    font-size: 1.2rem;
    font-weight: 200;
    margin-bottom: 1.5rem;
    color: #666;
}

.hero-btn {
    background-color: #e0f7fa;
    color: #1e90ff;
    border: 2px solid #1e90ff;
    padding: 0.9rem 2rem;
    cursor: pointer;
    border-radius: 50px;
    font-size: 1.1rem;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.hero-btn:hover {
    background-color: #1e90ff;
    color: white;
    transform: translateY(-3px);
}

.hero-image {
    flex: 0 0 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-image img {
    max-width: 100%;
    height: auto;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .hero {
        padding: 1rem;
    }

    .hero-content {
        flex-direction: column;
        text-align: center;
    }

    .hero-text {
        flex: 0 0 100%;
        align-items: center;
    }

    .hero-text h1 {
        font-size: 2rem;
        text-align: center;
    }

    .hero-text h2 {
        font-size: 1rem;
        text-align: center;
    }

    .hero-btn {
        font-size: 1rem;
        padding: 0.8rem 1.5rem;
    }

    .hero-image {
        display: none;
        /* Hide the image on smaller screens */
    }
}