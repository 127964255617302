.profile-custom-container {
    /* Adjust other sides as needed */
    border: 2px solid #ddd;
    /* Thicker, custom light border */
    box-shadow: 0 4px 8px rgba(116, 115, 115, 0.2);
    /* Larger, softer shadow */
    align-items: self-start;
}

.text-muted {
    font-size: 0.9rem;
    /* Small text */
    color: #6c757d;
    /* Grey color */
}

.text-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.save-btn-container {
    margin-right: 60px;
    /* Adjust this value as needed */
}



.camera-icon {
    display: none;
    /* Initially hidden */
}

.position-relative:hover .camera-icon {
    display: flex;
    /* Show on hover */
}

/* Hover Effect for Profile Image Container */
.profile-container:hover img {
    transform: scale(1.05);
    /* Slight zoom effect */
}

/* Show the Camera Icon on Hover */
.profile-container:hover .camera-icon {
    opacity: 1;
    /* Make icon visible */
}

.custom-alignment {
    margin-right: 200 !important;
    /* Remove extra margin if any */
    padding-right: 200 !important;
    /* Remove extra padding */
}

/* Ensure columns take up space without unnecessary gaps */
.custom-alignment .col-md-3,
.custom-alignment .col-md-9 {
    padding-left: 0;
    padding-right: 10;
}

.custom-spacing {
    padding-right: 20px;
    /* Internal spacing */
    margin-right: 50px;
    /* External spacing */
}

/* Close icon */
.close-icon {
    position: absolute;
    top: -10px;
    right: -5px;
    background: #f3f3f3;
    border: #817e7f;
    color: #535252;
    font-size: 20px;
    font-weight: bold;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.close-icon:hover {
    /* Blue border on hover and focus */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    /* Add shadow */
    border: 1px solid #007bff;
    transform: scale(1.1);
}

.save-edit-fixed-width {
    height: auto;
    width: auto;
    /* Set the desired width */
    text-align: center;
    /* Ensure text is centered */
    font-size: 14px;
}