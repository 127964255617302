.verification-completed-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(to right, #e0f7fa, #b3e5fc);
    /* Light gradient background */
    margin: 0;
}

.completed-card {
    display: flex;
    width: 80%;
    max-width: 900px;
    height: 60vh;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Box shadow for depth */
    background-color: #fff;
}

.completed-card-left {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    padding: 1rem;
    box-sizing: border-box;
    /* Ensure padding is included in width calculation */
}

.completed-image {
    width: 80%;
    height: 80%;
    max-width: 100%;
    /* Ensure it never exceeds the container */
    max-height: 100%;
    /* Ensure it never exceeds the container */
    object-fit: contain;
    /* Ensures the image is fully visible without being distorted */
    border-radius: 12px;
    /* Optional: To give rounded corners to the image */
}

.completed-wrapper {
    background-color: #d4edda;
    /* Light green background */
    padding: 10px;
    /* Add some padding for space around the text */
    border-radius: 10px;
    /* Rounded corners */
    font-size: 16px;
    border: 1px solid #c3e6cb;

    /* Light green border */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    /* Optional: Adds a subtle shadow */
    text-align: left;
    /* Center text inside */
}


.verification-title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: #333;
}

.verification-group {

    display: flex;
    /* Enable Flexbox */
    flex: 10;
    /* Allow it to take remaining space */
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    margin-bottom: 6rem;
    position: relative;
}

.completed-card-right {
    flex: 1;
    padding: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* Align the content at the top */
    position: relative;
    /* Make the parent relative so child can be positioned absolutely */


}

.verification-link {
    position: absolute;
    bottom: 2.2rem;
    right: 1rem;
}

.verification-link p {
    font-size: 0.875rem;
    color: #555;
}

.verification-link a {
    color: #1e90ff;
    text-decoration: none;
    font-weight: bold;
    padding: 0.5rem 0.5rem;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.verification-link a:hover {
    background-color: rgba(30, 144, 255, 0.1);
}