/* Custom Modal Styling */
/* .custom-modal .modal-dialog {
    max-width: 1000px;
    width: 95%;
    margin: auto;
}

.custom-modal .modal-content {
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    box-shadow: 8px rgba(144, 238, 144, 0.5);
    background-color: #ffffff;
} */

/* Modal Body Styling */
.suggestion-modal-body {
    height: 500px;
}

/* Close Button */
.suggestion-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 50%;
    color: #a4a1a1;
    border: 1px solid #a4a1a1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
}

.suggestion-close-button:hover {
    border: 1px solid #007bff;
    background-color: #fff;
    color: #007bff;
    /* Light green halo effect */
    transform: scale(1.1);
    /* Slight enlargement for emphasis */
}


.modal-left-column {
    padding: 20px;
}

.modal-right-column {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.right-image {
    width: 100%;
    height: auto;
    /* Adjust image size and positioning as needed */
}

.row-content {
    display: flex;
    justify-content: space-between;
    justify-content: center;
    justify-items: center;
    padding-left: 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    width: 100%;
    font-family: "Montserrat", sans-serif;
}

.option-row-content {
    width: 100%;
    padding: 4px;
    margin-left: 80px;
    display: flex;
    justify-content: space-between;
}

.option-row-content:hover {
    border-radius: 5px;
    cursor: pointer;
    background-color: #f7f4f4;
}

.content-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;
}

.left-column-heading {
    font-size: 26px;
    font-weight: 400;
    margin-top: 20px;
    margin-left: 20px;
    position: sticky;
    top: 0;
    background-color: white;
    padding: 10px;
    z-index: 1;
    text-align: start;
}

.left-row-header {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
    /* Reduced space between header and subheading */
}

.left-row-subheading {
    font-size: 14px;
    color: #777;
    margin-top: 0;
    /* Remove any additional margin */
}

.left-arrow-icon {
    font-size: 20px;
    /* Larger size for the ">" icon */
    font-weight: bold;
    margin-left: 10px;
    /* Space between text and icon */
}

.content-right {
    display: flex;
    align-items: center;
    justify-content: center;
}

.feedback-form {
    /* margin-top: 20px; */
    /* Space between feedback form and above content */
    width: 100%;
    /* Full width of the container */
}

.feedback-inputarea {
    width: 100%;
    height: 40px;
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

.feedback-textarea {
    width: 100%;
    /* Full width */
    height: 140px;
    /* Height of the textarea */
    padding: 10px;
    margin-bottom: 10px;
    /* Space below the textarea */
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    resize: none;
}

.feedback-textarea:focus,
.feedback-inputarea:focus {
    border-color: #007bff;
    /* Blue border */
    box-shadow: 0 0 2px rgba(0, 123, 255, 0.5);
    /* Add a subtle blue glow */
    outline: none;
    /* Remove the default outline */
}

.submit-button {
    width: 100%;
    /* Full width */
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #0056b3;
}

/* Text box and submit */
.heading-text {
    font-size: 24px;
    /* Adjust the text size if needed */
    font-weight: 400;
    margin: 0;
    text-align: start;
}

.text-box-content {
    width: 100%;
    padding: 4px;
    margin-left: 80px;
    display: flex;
}

.text-back-arrow {
    font-size: 20px;
    margin-left: 20px;
}

.text-back-arrow:hover {
    cursor: pointer;
}

/* New *Modal CSS */

/* Modal Overlay */
.custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal Container */
.custom-modal-container {
    background: #fff;
    border-radius: 15px;
    width: 500px;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Close Button */
.custom-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333;
}

/* Modal Content */
.custom-modal-content {
    display: flex;
    padding: 20px;
    gap: 15px;
}

/* Modal Text */
.custom-modal-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Modal Image */
.custom-modal-image img {
    max-width: 150px;
    max-height: 150px;
    border-radius: 10px;
    object-fit: cover;
}