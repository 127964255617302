.tabs-container {
    margin: 20px;
    padding: 10px;
    background-color: #f8f9fa;
    /* Light background for tabs */
    border-radius: 8px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.tabs-header {
    display: flex;
    border-bottom: 2px solid #dee2e6;
    margin-bottom: 10px;
}

.tab-button {
    padding: 5px 10px;
    text-align: center;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 300;
    color: #495057;
    transition: background-color 0.3s, color 0.3s;
}

.tab-button.active {
    background-color: #007bff;
    color: #fff;
    border-radius: 5px 5px 0 0;
}

.tab-button:hover {
    background-color: #f1f3f5;
    color: #007bff;
}

.tabs-content {
    padding: 15px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.tab-section {
    animation: fadeIn 0.3s ease-in-out;
}

.custom-light-bg {
    background-color: #b3bbc0;
    /* Light grey or any custom color */
    border-radius: 5px;
    /* Rounded corners */
    display: flex;
    /* Enables Flexbox */
    align-items: center;
    /* Vertically centers content */
    text-align: center;
    /* Centers text within the div */
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.custom-modal-delete {
    overflow: hidden;
    position: relative;
}

.custom-modal-end .modal-content {

    overflow: hidden;
    position: relative;
    box-shadow: 8px rgba(144, 238, 144, 0.5);
    /* Light green shadow */
    background-color: #ffffff;
    /* White background */
}

.filter-custom-modal-body {
    display: flex;
    padding: 20;
    margin: 0;
    width: 100%;
    height: 150px;
}

.custom-modal-body {
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0;
    margin: 0;
    height: 150px;
    /* Adjust the height if needed */
}

.delete-modal-body {
    padding: 0;
    margin: 0;
    height: 150px;
}

.oauth-body {
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0;
    margin: 0;
    height: 120px;
}

.oauth-download-container {
    margin: 10px;
    padding: 10px;
    background-color: #f8f9fa;
    /* Light background for tabs */
    border-radius: 8px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}