.forgot-password-page {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(to right, #e0f7fa, #b3e5fc);
    /* Light gradient background */
    margin: 0;
}

.forgotpassword-card {
    display: flex;
    width: 90%;
    max-width: 900px;
    height: 60%;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Box shadow for depth */
    background-color: #fff;
}

.forgotpassword-card-left .forgotpasswordlogo {
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: 100px;
    /* Adjust size of the logo */
}

.forgotpassword-card-left {
    background-color: #fff;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    position: relative;
}

.forgotpassword-image {
    width: 100%;
    height: 100%;
    max-width: 100%;
    /* Ensure it never exceeds the container */
    max-height: 100%;
    /* Ensure it never exceeds the container */
    object-fit: contain;
    /* Ensures the image is fully visible without being distorted */
    border-radius: 12px;
    /* Optional: To give rounded corners to the image */
}

.forgotpassword-card-right {
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* Centers content vertically */

    padding: 2rem;
    /* Adds padding for spacing */
}

.form-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    /* Align form elements to the right */
    justify-content: flex-end;
    /* Ensure items are placed at the top */
    width: 100%;
    max-width: 400px;
}

.forgotpassword-title {
    font-size: 2rem;
    font-weight: 600;
    color: #333;
    text-align: left;
    /* Aligns title to the right */
    background: linear-gradient(45deg, #818eff, #ff6e3e);
    background-clip: text;
    color: transparent;
}



.forgotpassword-btn-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    /* Full width to align button to the right */
    max-width: 300px;
    /* Limits the width of the button container */
    margin-top: 1rem;
    /* Adds spacing above the button */
}

.forgotpassword-btn {
    background: linear-gradient(105deg, #818eff, #ff6e3e);
    color: #fff;
    border: none;
    padding: 0.6rem 2rem;
    /* Increased padding for better alignment */
    border-radius: 25px;
    text-decoration: none;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.3s ease, transform 0.3s ease;
    margin-bottom: 3.5rem;
}

.forgotpassword-btn:hover {
    background: linear-gradient(105deg, #ff6e3e, #818eff);
    transform: scale(1.05);
    text-decoration: none;
    color: #fff;
}

.forgotpassword-form-group {
    width: 100%;
    /* Ensure the input takes full width */
    max-width: 100%;
    /* Make sure the input doesn't exceed the width */
    text-align: left;
    /* Align input label to the right */
}

.forgotpassword-form-group label {
    align-self: flex-start;
    /* Aligns label to the right */
    margin-bottom: 0.25rem;
    /* Space between label and input */
    font-weight: 400;
    /* Optional: make label bold */
    color: #8a8787;
}

/* Register Link Section */
.forgotpassword-login-link {
    margin-top: 1.2rem;
    justify-content: right;
    padding-right: 30px;
}

.forgotpassword-login-link p {
    font-size: 0.9rem;
    color: #555;
    justify-content: right;
}

.forgotpassword-login-link a {
    color: #1e90ff;
    text-decoration: none;
    font-weight: 500;
    border-radius: 5px;
}

.forgotpassword-login-link a:hover {
    text-decoration: none;
    background: linear-gradient(15deg, #818eff, #ff6e3e);
    background-clip: text;
    color: transparent;
}

.forgotpassword-text {
    font-size: 0.9rem;
    /* Adjust the size as needed */
    color: #555;
    /* Optional: Adjust color for readability */
    margin-top: 0.5rem;
}