.app-header {
    transition: all 0.5s;
    z-index: 997;
    height: 60px;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    background-color: #fff;
    padding-left: 20px;
}

/* Search Bar */
/* Toggle Sidebar Button */
.app-header .toggle-sidebar-btn {
    font-size: 32px;
    padding-left: 10px;
    cursor: pointer;
    color: #012970;
}

.app-header .logo-container {
    display: flex;
    align-items: center;
}

.app-header .brand-img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-left: 10px;
    /* spacing between logo and brand image */
}