.title-text {
    font-size: 2.2rem;
    color: #333;
    /* Dark color for the text */
    display: inline-block;
    /* Add some padding inside the border */
    border-radius: 8px;
    font-weight: 800;
    color: #333;
    background: linear-gradient(105deg, #818eff, #ff6e3e);
    background-clip: text;
    color: transparent;
    /* Dark color for the text */

}

/* Responsive styles for tablet devices */
@media (min-width: 768px) and (max-width: 1024px) {
    .title-text {
        font-size: 2rem;
        /* Slightly reduce the font size for tablets */
        margin-bottom: 0.75rem;
        /* Adjust the margin */
    }
}

/* Responsive styles for mobile devices */
@media (max-width: 767px) {
    .title-text {
        font-size: 1.8rem;
        /* Further reduce font size for mobile devices */
        margin-bottom: 0.5rem;
        /* Adjust the margin */
    }
}

/* Responsive styles for very small screens */
@media (max-width: 480px) {
    .title-text {
        font-size: 1.6rem;
        /* Reduce font size for very small screens */
        margin-bottom: 0.4rem;
        /* Adjust the margin */
    }
}