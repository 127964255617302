/* RegistrationPage.css */

.registration-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(to right, #e0f7fa, #b3e5fc);
    /* Light gradient background */
    margin: 0;

}

.registration-card {

    width: 90%;
    max-width: 1000px;
    height: 68%;
    display: flex;
    flex-direction: row;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Box shadow for depth */
    background-color: #fff;
}

.registration-card-left {
    background-color: #f0f0f0;
    width: 40%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

}

.registration-logo {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 120px;
}

.registration-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.registration-card-right {
    width: 60%;
}

.registration-title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    background: linear-gradient(35deg, #818eff, #ff6e3e);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    /* Ensure the gradient covers only the text */
}

.registration-sub-title {
    font-size: 1rem;
    font-weight: 500;
    color: #333;
    margin-bottom: 1.5rem;
}

input.form-control {
    padding-right: 40px;
    /* Add padding on the right to make space for the button */
}

.password-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.show-hide-btn {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
}

.registration-btn-container {
    display: flex;
    justify-content: flex-end;
}

.registration-btn {
    background: linear-gradient(135deg, #818eff, #ff6e3e);
    color: #fff;
    border: none;
    padding: 0.55rem 2rem;
    border-radius: 25px;
    text-decoration: none;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.3s ease, transform 0.3s ease;
}

.registration-btn:hover {
    background: linear-gradient(135deg, #ff6e3e, #818eff);
    color: #fff;
}

.registration-btn:disabled {
    background: #ebc1c1;
    /* Change background color */
    cursor: not-allowed;
    /* Change cursor to indicate it's disabled */
    opacity: 0.7;
    /* Make it appear faded */
    color: #fff;
}


/* Register Link Section */
.registerlogin-link {
    margin-top: 1.2rem;
    justify-content: right;
}

.registerlogin-link p {
    font-size: 0.80rem;
    color: #555;
    justify-content: right;
}

.registerlogin-link a {
    color: #1e90ff;
    text-decoration: none;
    font-weight: 500;
    border-radius: 5px;
}

.registerlogin-link a:hover {
    text-decoration: none;
    background: linear-gradient(15deg, #818eff, #ff6e3e);
    background-clip: text;
    color: transparent;
}

/* RegistrationPage.css */

.registrationbody-container {
    width: 100%;
    /* Full width within the form */
    max-width: 100%;
    /* Optional: set a max width */
    height: 226px;
    /* Set a fixed height */
    overflow-y: auto;
    /* Enable vertical scroll if content exceeds the fixed height */
    padding: 10px;
    overflow-x: hidden;
    overflow-y: hidden;
    margin-top: -20px;
}

/* Optional: Style scrollbar for better appearance */
.registrationbody-container::-webkit-scrollbar {
    width: 8px;
}

.registrationbody-container::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
}

.registrationbody-container::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

.registrationdropdown-icon {
    pointer-events: none;
    /* Prevents icon from blocking clicks on the select box */
    padding-right: 10px;
    /* Adds spacing between the icon and the select edge */
    color: #6c757d;
    /* Sets color to match typical dropdown arrow colors */
}

.registrationdropdown-select {
    cursor: pointer;
}

/* Body for the page display */
.registrationslide-body {
    transition: transform 0.5s ease, opacity 0.5s ease;
}

/* Sliding to the left for "Next" */
.slide-out-left {
    transform: translateX(100%);
    opacity: 0;
}

.slide-in-right {
    transform: translateX(-100%);
    opacity: 0;
}

.slide-to-center {
    transform: translateX(1);
    opacity: 1;
}

/* Sliding to the right for "Previous" */
.slide-out-right {
    transform: translateX(100%);
    opacity: 0;
}

.slide-in-left {
    transform: translateX(-100%);
    opacity: 0;
}

/* Title of the summary */
.scrollable-section {
    /* Enable vertical scrolling */
    padding: 8px;
    /* Optional: Add padding for better appearance */
    border: 1px solid #ccc;
    /* Optional: Add a border for visual clarity */
    border-radius: 5px;
    /* Optional: Rounded corners */
    background-color: #f9f9f9;
    /* Optional: Background color */
    margin-top: -10px;
}

.form-data-summary h3 {
    font-size: 1.2rem;
    color: #333;
}

/* Styling for each summary item */
.summary-item {
    margin-bottom: 0.15rem;
    font-size: 0.9rem;
    color: #555;
}

/* Container for region and country side by side */
.summary-item.row {
    display: flex;
    gap: 2rem;
}

/* Adjust styling for region and country to fit side by side */
.summary-item.row div {
    flex: 1;
    margin-bottom: 0.1rem;
}

/* Add space to the left of the checkbox */
input[type="checkbox"] {
    margin-left: 10px;
    /* Adjust this value as needed */
}

/* Add space between the checkbox and the label */
label {
    margin-left: 10px;
    /* Adjust this value as needed */
}