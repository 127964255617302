/* Contact Us section */
.contact-section {
    position: relative;
    padding: 2rem 0;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
}

/* Google map */
.map-container {
    width: 100%;
    height: 450px;
    position: relative;
    box-sizing: border-box;
    padding: 0 2rem;
}

.google-map {
    width: 100%;
    height: 100%;
    border: 4px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
}



/* Contact form container */
.contact-form-container {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    display: flex;
    align-items: stretch;
    width: 45%;
    height: 380px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 1;
}

/* Contact details on the left */
.contact-details {
    background: linear-gradient(to right, #e0f7fa, #ffffff);
    padding: 1rem;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
}

.contact-details h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: #333;
}

.contact-details p {
    margin: 0.5rem 0;
    color: #555;
    text-align: left;
}

/* Contact form on the right */
.contact-form {
    width: 50%;
    padding: 1rem;
    background-color: #f4f4f4;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.input-field {
    margin-bottom: 0.8rem;
}

.input-field input,
.input-field textarea {
    width: 100%;
    padding: 0.6rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 0.9rem;
}

.input-field textarea {
    resize: none;
    height: 80px;
}

.send-btn {
    padding: 0.6rem 1rem;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 0.9rem;
    width: 90px;
    text-align: center;
    background: linear-gradient(45deg, #1e90ff, #00bfff);
    color: #fff;
    transition: background 0.3s ease, transform 0.3s ease;
    margin-left: 0;
    /* Ensures it stays aligned to the left */
    float: left;
    /* Align the button to the left */
}

.send-btn:hover {
    background: linear-gradient(45deg, #00bfff, #1e90ff);
    transform: scale(1.05);
    box-shadow: 0 0 15px 5px rgba(30, 144, 255, 0.6),
        0 0 30px 10px rgba(0, 191, 255, 0.4);
}

/* Custom marker on the map */
.marker {
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -100%);
    width: 80px;
    height: 80px;
    background: url('../../Resource/Innodhee-loco.png') no-repeat center center;
    background-size: cover;
    border-radius: 50%;
    z-index: 2;
    transition: transform 0.3s ease;
}

.marker:hover {
    animation: bounce 1.5s ease infinite;
    cursor: pointer;
}

/* Bounce animation keyframes */
@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translate(-50%, -100%);
    }

    40% {
        transform: translate(-50%, -130%);
    }

    60% {
        transform: translate(-50%, -115%);
    }
}

/* Responsive styles */
@media (max-width: 768px) {
    .contact-form-container {
        flex-direction: column;
        width: 90%;
        height: auto;
        bottom: 1rem;
        right: 1rem;
        padding: 1rem;
    }

    .contact-details,
    .contact-form {
        width: 100%;
        padding: 1rem;
    }

    .map-container {
        padding: 0 1rem;
    }

    .input-field input,
    .input-field textarea {
        font-size: 0.8rem;
    }

    .send-btn {
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
        width: 80px;
    }
}

@media (max-width: 480px) {
    .contact-form-container {
        width: 95%;
        padding: 0.5rem;
    }

    .contact-details h3 {
        font-size: 1rem;
    }

    .contact-details p {
        font-size: 0.8rem;
    }

    .send-btn {
        padding: 0.4rem 0.8rem;
        font-size: 0.8rem;
        width: 75px;
    }
}