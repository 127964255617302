/* Decrease the size of the back arrow */
.back-arrow {
    width: 20px;
    /* Adjust the width */
    height: auto;
    /* Maintain the aspect ratio */
    margin-right: 10px;
    /* Add some spacing, if needed */
    cursor: pointer;
    /* Makes it clear that it's clickable */
    display: inline-block;
    /* Ensures proper alignment */
    filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.2));
    /* Optional: Adds a subtle shadow for better visibility */
    transition: transform 0.2s ease-in-out;
    /* Smooth hover effect */
}

/* Optional: Add a hover effect */
.back-arrow:hover {
    transform: scale(1.1);
    /* Slightly enlarge the arrow on hover */
}


/* Main wrapper containing both left and right parts */
.details-wrapper {
    display: flex;
    justify-content: left;

    /* Center the divs horizontally */
    gap: 20px;
    /* Space between left and right parts */
    margin-top: 20px;
    /* Optional margin for spacing */
}


/* Common styles for individual sections */
.details-left-side {
    flex: 1 1 50%;
    /* Flexible width with a base of 40% */
    min-width: 300px;
    /* Ensures it doesn’t get too small */
    background-color: #f8f9fa;
    border-radius: 10px;
    border: 1px solid #dee2e6;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    /* border: 2px solid #ff6600;
    border-radius: 10px; */
}

.details-right-side,
.details-exright-side {
    flex: 1 1 30%;
    /* Flexible width with a base of 30% */
    min-width: 250px;
    /* Ensures items don’t get too small */
    background-color: #f8f9fa;
    border-radius: 10px;
    border: 1px solid #dee2e6;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    /* border: 2px solid #ff6600;
    border-radius: 10px; */
}

/* Labels column */
.labels-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: start;
    gap: 2px;
    /* border: 2px solid #11ff00;
    border-radius: 10px; */
}

/* Values column */
.values-column {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    gap: 2px;
    /* border: 2px solid #007bff;
    border-radius: 10px; */

}

.values-extreme-column {
    flex: auto;
    padding-top: 0;
    margin-top: -10px;
}

/* Label styling */
.left-label,
.right-label {
    font-weight: 500;
    color: #141414;
    font-size: 0.9rem;
    white-space: nowrap;
    /* Prevents wrapping */

}

/* Value styling */
.left-value,
.right-value {
    font-size: 0.9rem;
    color: #555;
    width: 100%;
}

/* Mobile responsiveness */
@media (max-width: 768px) {

    .details-left-side,
    .details-right-side,
    .details-exright-side {
        flex: 1 1 100%;
        /* Full width on smaller screens */
    }
}

.details-pagination {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-top: 20px;
}

.details-page-size-selector {
    margin-left: auto;
    /* Align to the right */
}

.details-page-size-selector select {
    padding: 4px 8px;
    /* Reduce padding for smaller size */
    border: 1px solid #ccc;
    /* Light color border */
    border-radius: 5px;
    /* Rounded corners */
    font-size: 0.9rem;
    /* Decrease font size */
    width: 65px;
    /* Adjust width to fit content */
    outline: none;
    /* Remove outline on focus */
    transition: border-color 0.3s ease;
    /* Smooth border transition */
    cursor: pointer;
}


.details-table-wrapper {
    margin-top: 10px;
    /* Optional margin for spacing */
    padding: 20px;
    background-color: #f8f9fa;
    /* Light background color */
    border-radius: 15px;
    /* Rounded corners */
    border: 1px solid #dee2e6;
    /* Light gray border */
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    /* Shadow effect */
}

/* Table custom CSS */
.details-table {
    width: 100%;
    border-collapse: separate;
    margin-top: 10px;
    border-spacing: 0;
    border: 2px solid #ccc;
    /* Border color and width for the table */
    border-radius: 10px;
    /* Rounded corners for the table */
    overflow: hidden;
    /* Prevents the border-radius from being cut off */
}

.details-table-top-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.details-dropdown-filter {
    width: 100%;
    height: auto;
    font-size: 0.7rem;
    /* Full width to match column size */
    border: 1px solid #ccc;
    /* Light color border */
    border-radius: 5px;
    /* Rounded corners */
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    /* Smooth transition for background and shadow */
}

/* Halo effect on hover */
.details-dropdown-filter:hover {
    border-color: #007BFF;
    /* Change border color on hover */
    box-shadow: 0 0 6px rgba(0, 123, 255, 0.5);
    /* Add shadow on hover */
    cursor: pointer;
}

.details-datetime {
    font-size: 0.9rem;
    color: #7c7c7c;
}

.filter-row-container {
    background-color: #ffffff;
    /* Change to your desired color */
    padding-top: 10px;
    /* Optional padding */
    border-radius: 8px;
    /* Optional: Add rounded corners */
    margin-bottom: 15px;
    /* Optional: Space below the filter row */

}

.details-input-filter {
    width: 100%;
    padding: 5px;
    font-size: 14px;
    /* border: 1px solid #ccc; */
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
}

.details-package-name {
    max-width: 220px;
    /* Adjust width as needed */
    white-space: nowrap;
    /* Prevent text from wrapping */
    overflow: hidden;
    /* Hide overflowing text */
    text-overflow: ellipsis;
    /* Add ellipsis when the text overflows */
    cursor: pointer;
    /* Change cursor to indicate interactivity */
}


.details-status-dropdown-filter-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    width: 100%;
    cursor: pointer;
    /* Increase width */
}

.details-status-dropdown-filter {
    width: 100%;
    font-size: 12px;
    /* Smaller font size for sleek appearance */
    border: none;
    outline: none;
    background-color: transparent;
    box-shadow: none !important;
    cursor: pointer;
}

.details-status-dropdown-filter .react-select__control {
    min-height: 28px;
    /* Decrease control height */
    border: none;
    background: none;
    box-shadow: none;
    cursor: pointer;
}

.details-status-dropdown-filter .react-select__value-container {
    padding: 2px 4px;
}

.details-status-dropdown-filter .react-select__indicator-separator {
    display: none;
}

.details-status-dropdown-filter .react-select__dropdown-indicator {
    padding: 2px;
    color: #888;
    cursor: pointer;
}

.values-column .details-package-name {
    justify-content: space-between;
    /* Ensures space between the text and the icon */
    flex: 1;
    /* Ensures the container spans the available width */
}

.values-column .custom-copy-icon {
    margin-left: 2px;
    /* Pushes the icon to the right */
}