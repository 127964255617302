.mission-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
    min-height: 50vh;
    /* Adjusted container height */
}

.mission-card {
    display: flex;
    align-items: center;
    /* Align items at the top */
    width: 100%;
    max-width: 1200px;
    background: linear-gradient(to right, #e0f7fa, #ffffff);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    overflow: hidden;
    margin: 0 2rem;
    padding: 1.5rem;
    /* Reduced padding */
    max-height: 500px;
    /* Decreased card height */
    box-sizing: border-box;
    /* Include padding in height calculation */
}

.mission-image {
    width: 35%;
    /* Reduced image width */
    height: auto;
    border-radius: 12px;
    overflow: hidden;
}

.mission-image img {
    width: 100%;
    height: auto;
    /* Maintain aspect ratio */
    object-fit: cover;
    border-radius: inherit;
}

.mission-content {
    width: 60%;
    padding: 1.5rem 2rem;
    text-align: left;
}


.mission-content p {
    font-size: 0.95rem;
    color: #555;
    line-height: 1.6;
    margin: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .mission-card {
        flex-direction: column;
    }

    .mission-image,
    .mission-content {
        width: 100%;
    }

    .mission-content {
        padding: 1.5rem;
    }

    .mission-content h2 {
        font-size: 1.5rem;
    }

    .mission-content p {
        font-size: 0.9rem;
    }
}