/* Modal Styles */
.loadermodal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(20, 20, 20, 0.3);
    /* Adjust opacity for transparency */
    z-index: 9999;
}

/* Center both logo and text within the modal */

.loaderlogo {
    width: 5%;
    /* Adjust size for the spinner */
    height: 12%;
}