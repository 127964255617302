/* Footer Styles */
.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    /* Decreased padding to reduce height */
    background: radial-gradient(circle at left, #e0f7fa, #b3e5fc, #81d4fa);
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    font-size: 0.9rem;
    /* Slightly smaller font size */
    color: #000;
    flex-wrap: wrap;
    min-height: 50px;
    /* Set a minimum height */
}

/* Footer left section */
.footer-left {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    /* Reduced gap between items */
    font-size: 0.9rem;
    flex: 1;
    flex-wrap: wrap;
}

.footer-left i {
    font-size: 1.5rem;
    color: #00bfff;
}

.footer-text {
    line-height: 1.2;
    /* Adjusted line height for compactness */
    text-align: left;
    color: rgba(0, 0, 0, 0.8);
}

.logofooter {
    width: 80px;
    /* Reduced logo size */
    height: auto;
}

/* Footer right section */
.footer-right {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    flex: 1;
}

/* Footer links */
.footer-right a {
    color: #333;
    text-decoration: none;
    font-size: 0.9rem;
    transition: transform 0.3s ease;
}

.footer-right a:hover {
    color: #1e90ff;
    transform: translateY(-3px);
    /* Slight hover lift */
}

/* Responsive Styles */
@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        /* Stack sections vertically */
        text-align: center;
        /* Center align text */
        padding: 1.5rem 1rem;
        /* Adjust padding for smaller screens */
    }

    .footer-left,
    .footer-right {
        justify-content: center;
        /* Center content */
    }

    .footer-right {
        gap: 1rem;
        /* Reduce gap between links */
    }

    .footer-left {
        margin-bottom: 1rem;
        /* Add space between left and right sections */
    }
}

@media (max-width: 480px) {
    .footer {
        padding: 1rem;
        /* Further reduce padding for very small screens */
    }

    .footer-left i {
        font-size: 1.2rem;
        /* Adjust icon size for smaller screens */
    }

    .footer-right a {
        font-size: 1rem;
        /* Decrease font size */
    }
}