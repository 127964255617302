.destination-profile {
    width: 100%;
    margin: 20px 0;

    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tabs-header {
    display: flex;
    justify-content: flex-start;
    /* Align tabs to the left */
    gap: -1px;
    /* Minimize gap between tabs for seamless border integration */
    position: relative;
    z-index: 2;
}

.tab-item {
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 300;
    color: #333;
    background-color: #fff;
    border: 1px solid #ccc;
    border-bottom: none;
    /* Remove bottom border */
    border-radius: 8px 8px 0 0;
    /* Rounded top corners */
    position: relative;
    z-index: 2;

    /* Shadows on top, left, and right */
    transition: all 0.3s ease-in-out;
}


.tab-item.active {
    background-color: #fff;
    color: #302f2f;
    font-weight: 400;
    border-bottom: none;
    /* Keep the bottom border removed for the active tab */
    z-index: 3;
    box-shadow: 2px -2px 6px rgba(0, 0, 0, 0.2),
        -2px -2px 6px rgba(0, 0, 0, 0.2);
}

.tab-item:not(.active):hover {
    background-color: #dcdcdc;
    border-color: #aaa;
    color: #000;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

.tabs-content {
    padding: 20px;
    background-color: #fff;
    border-radius: 0 8px 8px 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2),
        2px 0 6px rgba(0, 0, 0, 0.2),
        2px 0 6px rgba(0, 0, 0, 0.2);
    margin-top: 1px;
    position: relative;
    z-index: 3;
}

/* Main layout */




/* Right column styles */
.right-column .info-box {
    font-size: 16px;
    line-height: 1;
    color: #555;
}

.right-column .info-box h3 {
    font-size: 10px;
    margin-bottom: 10px;
    color: #333;
}

.right-column .info-box ul {
    padding-left: 20px;
}

.right-column .info-box ul li {
    margin-bottom: 5px;
}

.inner-value input {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    padding: 4px;
    padding-left: 10px;
}

.inner-value input[type="date"] {
    width: auto;
    padding: 8px;
    font-size: 14px;
}

/* Custom button for test and save */



/* Dropdown container with icon */
.destination-dropdown-container {
    position: relative;
    width: 100%;
    /* Make it responsive */
}

.destination-custom-dropdown {
    appearance: none;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 14px;
    color: #707070;
    width: 100%;
    cursor: pointer;
    outline: none;
    transition: border-color 0.2s ease-in-out;
}

/* Add hover effect */
.destination-custom-dropdown:hover {
    border-color: #007bff;
    box-shadow: 0 0 6px rgba(76, 146, 216, 0.6);
}

/* Add focus effect */
.destination-custom-dropdown:focus {
    border-color: #007bff;
    box-shadow: 0 0 6px #4c92d899;
}

/* Optional: Style the option text */
.destination-custom-dropdown option {
    padding: 8px;
    background-color: #fff;
    color: #5c5c5c;
}


/* Icon container */
.dropdown-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
    font-size: 14px;
    color: #666;
}

/* Optional: Adjust icon hover color */
.destination-custom-dropdown:hover,
.dropdown-icon {
    color: #a1a1a1;
}

/* Calendar Selection */

.destination-calendar-container {
    position: flex;
    display: flex;
    align-items: center;
    width: 100%;
}

.destination-date-picker {
    width: 100%;
    max-width: 300px;
    /* Allows the input to take up available space */
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    position: relative;
    z-index: 1;
    border-color: #007bff;
    cursor: pointer;
    /* Ensure the input field is above the popper */
}

.destination-date-picker:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(72, 150, 235, 0.5);
}

.destination-custom-calendar-icon {
    position: relative;
    right: 25px;
    /* Adjust to align icon properly */
    color: #a1a1a1;
    font-size: 18px;
    cursor: pointer;
    z-index: 2;
}

.destination-custom-calendar-icon:hover {
    color: #007bff;
}

/* Update values all the items */

.update-inner-row {
    width: 100%;
    display: flex;
    /* Use flexbox for layout */
    flex-direction: row;
    /* Align items in a column */
    align-items: flex-start;
    /* Align items to the left (or change to center/right as needed) */
}

.update-inner-value {

    flex: 1;
    /* Divide width equally among all values */
    text-align: center;
    /* Center-align content inside each value */
    padding: 8px;
    /* Add some padding for readability */
    border: 1px solid #ccc;
    /* Optional: Add borders for visual separation */
}


.action-button {
    flex: 1;
    /* Ensures buttons take up equal width */
    margin: 0 5px;
    /* Add space between buttons */
    text-align: center;
    /* Center-align content inside the button */
    border: none;
    /* Remove default button border */
    background-color: #007bff;
    /* Primary color */
    color: #fff;
    /* Text color */
    border-radius: 4px;
    /* Rounded corners */
    padding: 5px;
    /* Padding for button content */
    cursor: pointer;
    /* Pointer cursor on hover */
}

.action-img {
    width: 16px;
    /* Adjust as per the icon size */
    height: auto;
    object-fit: contain;
}

.action-button:hover {
    background-color: #0056b3;
    /* Darker shade for hover */
}

/* New CSS Please update here all the components */
.two-column-layout {
    display: flex;
    gap: 20px;
    background-color: #fff;
}

.left-column,
.right-column {
    flex: 1;
    padding: 10px;
}

/* Inner table layout */
.inner-table {
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
}

.inner-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 4px 8px;
    border-bottom: 1px solid #ccc;
    background-color: #fff;
    overflow: visible;
}


.inner-label {
    flex: 0 0 24%;
    /* Fixed width proportion of 60% */
    text-align: right;
    font-weight: bold;
    padding-right: 10px;
    border-right: 1px solid #ccc;
    padding-left: 5px;
    /* Reduce this to decrease left spacing further */
    padding-right: 5px;
    /* Space between label and the divider */
    margin-left: 0;
}

.inner-value {
    flex: 0 0 76%;
    /* Fixed width proportion of 60% */
    text-align: left;
    /* Left-align the value */
    color: #555;
    padding-left: 10px;
}

.destination-custom-border {
    border: 3px solid #b3bbc0;
    /* Green border */
}

.destination-tb-custom-border {
    margin-top: 10px;
    border: 3px solid #b3bbc0;
    justify-content: center;
    /* Green border */
}

/* Connection button save and cancel */
.button-row {
    display: flex;
    /* Use flexbox for alignment */
    gap: 10px;
    /* Space between the buttons */
    margin-top: 10px;
    /* Add some space above the button row */
}

.custom-button {
    flex: 1;
    /* Equal width for all buttons */
    padding: 5px 10px;
    /* Padding for the buttons */
    background-color: #007bff;
    /* Button background color */
    color: #fff;
    /* Button text color */
    border: none;
    /* Remove default borders */
    border-radius: 5px;
    /* Slightly rounded corners */
    font-size: 16px;
    /* Font size for better readability */
    cursor: pointer;
    /* Change cursor to pointer */
    transition: background-color 0.3s ease-in-out, transform 0.2s ease-in-out;
}

.custom-button:hover {
    background-color: #0056b3;
    /* Darker shade on hover */
    transform: scale(1.05);
    /* Slightly enlarge the button on hover */
}

.custom-button:active {
    background-color: #004494;
    /* Even darker shade on click */
    transform: scale(0.98);
    /* Slightly shrink the button on click */
}

.custom-button:disabled {
    background: #99c0ec;
    /* Change background color */
    cursor: not-allowed;
    /* Change cursor to indicate it's disabled */
    opacity: 0.6;
    /* Make it appear faded */
    transform: none;
    /* Remove transform effects */
}


/* ---------------------------------- */

/* .react-table {
    table-layout: fixed;
    width: 100%;
}

.react-table th,
.react-table td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
} */

/* Modal css for deleting records */

.custom-modal-delete {
    overflow: hidden;
    position: relative;
}

.delete-modal-body {
    align-items: center;
    justify-content: center;
    display: flex;
    padding-left: 0;
    margin-left: 0;
    height: 150px;
}


.custom-modal-end .modal-content {

    overflow: hidden;
    position: relative;
    box-shadow: 8px rgba(144, 238, 144, 0.5);
    /* Light green shadow */
    background-color: #ffffff;
    /* White background */
}


.destination-modal-body {

    padding-left: 0;
    margin-left: 0;
    height: 150px;
    /* Adjust the height if needed */
}

/* Table */