@import url('https://fonts.googleapis.com/css2?family=Caladea:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

body {
  background: rgba(196, 192, 192, 0.2);
  font-family: "Montserrat", sans-serif;
}

/* Global Styles for sections */
section {
  padding: 0px 0;
  /* General padding for all sections */
  scroll-margin-top: 80px;
  /* This ensures the section appears correctly when scrolling */
  /* Adjust 80px according to your navbar height */
}

/* Specific section styles (customize as needed) */

/* Hero Section */
#hero-section {
  padding: 20px 0;
  height: 100vh;
  /* Ensure it covers full screen */
}

/* About Section */
#about-section {
  padding: 0px 0;
  scroll-margin-top: 160px;
  /* Adjust for navbar height */
}

/* Services Section */
#services-section {
  padding: 0px 0;
  scroll-margin-top: 160px;
}

/* Products Section */
#products-section {
  padding: 0px 0;
  scroll-margin-top: 160px;
}

#whyus-section {
  padding: 0px 0;
  scroll-margin-top: 160px;
}

#mission-section {
  padding: 0px 0;
  scroll-margin-top: 160px;
}

/* Testimonials Section */
#testimonials-section {
  padding: 60px 0;
  scroll-margin-top: 190px;
}

/* Contact Section */
#contact-section {
  padding: 0px 0;
  scroll-margin-top: 160px;
}

/* Footer */
footer {
  padding: 20px;
  text-align: center;
}

.modal-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(189, 189, 189, 0.9);
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Ensure it appears above all other elements */
}


.btn-submit {
  background: linear-gradient(105deg, #818eff, #ff6e3e);
  color: #fff;
  border: none;
  padding: 0.8rem 2rem;
  /* Increased padding for better alignment */
  border-radius: 25px;
  text-decoration: none;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s ease, transform 0.3s ease;
}

.btn-submit:hover {
  background: linear-gradient(105deg, #ff6e3e, #818eff);
  transform: scale(1.05);
  text-decoration: none;
}

.btn-submit:disabled {
  background: #ebc1c1;
  /* Change background color */
  cursor: not-allowed;
  /* Change cursor to indicate it's disabled */
  opacity: 0.6;
  /* Make it appear faded */
  transform: none;
  /* Remove transform effects */
}

/* Button styles */
.global-button {
  margin-left: 10px;
  /* Space between input and button */
  padding: 5px 10px;
  /* Padding for the button */
  border: none;
  /* Remove default border */
  border-radius: 5px;
  /* Rounded corners */
  background: linear-gradient(105deg, #818eff, #b9bddd);
  color: #fff;
  border: none;
  /* Button background color */
  font-size: 0.9rem;
  /* Font size for the button */
  cursor: pointer;
  /* Pointer cursor on hover */
  transition: background-color 0.3s ease;
  /* Smooth transition for hover */
}

/* Button hover effect */
.global-button:hover {
  background: linear-gradient(105deg, #b9bddd, #818eff);
  transform: scale(1.05);
  text-decoration: none;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  /* Center the pagination controls */
  flex: 1;
  text-align: center;
  gap: 0.3rem;
}

.pagination-controls button {
  cursor: pointer;
  padding: 1px 5px;
  background-color: #f4f4f4;
  cursor: pointer;
  border: 1px solid #5a5a5a;
  /* Light color border */
  border-radius: 5px;
  /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s;
}

.pagination-controls button[disabled] {
  cursor: not-allowed;
  opacity: 0.7;
  color: #979797;
  /* Dim disabled buttons */
}

.pagination-controls button:hover:not(:disabled) {
  /* background: linear-gradient(135deg, #818eff, #ff6e3e); */
  background: #818eff;
  /* Add gradient background */
  -webkit-background-clip: text;
  /* Clip the background to the text */
  -webkit-text-fill-color: transparent;
  /* Make the text color transparent */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  /* Add shadow for depth */
}


.pagination-controls span {
  font-size: 1rem;
  font-weight: 300;
}


.pagination-goto-page {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.pagination-goto-input {
  width: 55px;
  /* Smaller width */
  padding: 0.2rem;
  /* Adjust padding for smaller height */
  text-align: center;
  /* Center align the text */
  background-color: #f4f4f4;
  cursor: pointer;
  border: 1px solid #5a5a5a;
  /* Light border */
  border-radius: 5px;
  /* Rounded corners */
  font-size: 0.8rem;
  /* Slightly smaller font */
  outline: none;
  /* Remove default focus outline */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.pagination-goto-input:focus {
  border-color: #007bff;
  /* Highlight border on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  /* Subtle shadow effect */
}

.retry-count {
  position: absolute;
  top: 10px;
  right: 60px;
  width: 60%;
  height: 100%;
  background-color: #e7f9e7;
  color: #000000;
  border: 1px solid #a4a1a1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
}

.pagination-goto-button {
  padding: 0.3rem 0.5rem;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pagination-goto-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.custom-moreinfo-icon {
  background-color: #fff;
  color: #0d6efd;
  font-size: 0.8rem;
  border: 1px solid #0d6efd;
  border-radius: 3px;
  justify-content: center;
  padding: 0.15rem;
  padding-bottom: 0.05rem;
  margin-left: 10px;
}

.custom-moreinfo-icon:hover {
  background-color: #0d6efd;
  color: #fff;
  cursor: pointer;
}

.custom-connectiontest-icon {
  background-color: #fff;
  color: #0d6efd;
  font-size: 0.8rem;
  border: 1px solid #0d6efd;
  border-radius: 3px;
  justify-content: center;
  padding: 0.15rem;
  padding-bottom: 0.05rem;
  margin-left: 10px;
}

.custom-connectiontest-icon:hover {
  background-color: #0d6efd;
  color: #fff;
  cursor: pointer;
}


.custom-edit-icon {
  background-color: #fff;
  color: #0d6efd;
  font-size: 0.8rem;
  border: 1px solid #0d6efd;
  border-radius: 3px;
  justify-content: center;
  padding: 0.15rem;
  padding-bottom: 0.05rem;
  margin-left: 10px;
}

.custom-edit-icon:hover {
  background-color: #0d6efd;
  color: #fff;
  cursor: pointer;
}

.custom-delete-icon {
  background-color: #fff;
  color: #0d6efd;
  font-size: 0.8rem;
  border: 1px solid #0d6efd;
  border-radius: 3px;
  justify-content: center;
  padding: 0.15rem;
  padding-bottom: 0.05rem;
  margin-left: 10px;
}

.custom-delete-icon:hover {
  background-color: #0d6efd;
  color: #fff;
  cursor: pointer;
}

.custom-copy-icon {
  background-color: #fff;
  color: #0d6efd;
  font-size: 0.8rem;
  border: 1px solid #0d6efd;
  margin-left: 3px;
  border-radius: 3px;
  justify-content: center;
  padding: 0.15rem;
  padding-bottom: 0.05rem;
  line-height: 0.2;
}


.custom-copy-icon:hover {
  background-color: #0d6efd;
  color: #fff;
  cursor: pointer;
}

.custom-back-icon {
  background-color: #fff;
  color: #0d6efd;
  border: 1px solid #0d6efd;
  border-radius: 4px;
  justify-content: center;
  padding: 0.15rem;
  font-size: 1rem;
  margin-right: 5px;
}

.custom-back-icon:hover {
  background-color: #0d6efd;
  color: #fff;
  cursor: pointer;
}

.custom-repeat-icon {
  background-color: #fff;
  color: #0d6efd;
  border: 1px solid #0d6efd;
  border-radius: 4px;
  justify-content: center;
  padding: 0.15rem;
  padding-bottom: 0.05rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  font-size: 0.9rem;
  /* justify-content: center;
  line-height: 1.6;
  padding: 0.2rem;
  font-size: 1.3rem; */
}

.custom-repeat-icon:hover {
  background-color: #0d6efd;
  color: #fff;
  cursor: pointer;
}

.custom-confirm-icon {
  background-color: #fff;
  color: #0d6efd;
  border: 1px solid #0d6efd;
  border-radius: 4px;
  justify-content: center;
  font-size: 1rem;
  padding: 0.2rem;
  margin-left: 0.6rem;
  line-height: 0.6;
}

.custom-confirm-icon:hover {
  background-color: #0d6efd;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.table-custom-moreinfo-icon {
  background-color: #fff;
  color: #0d6efd;
  font-size: 0.8rem;
  border: 1px solid #0d6efd;
  border-radius: 3px;
  justify-content: center;
  padding: 0.15rem;
  padding-bottom: 0.05rem;
  margin-left: 10px;
}

.table-custom-moreinfo-icon:hover {
  background-color: #0d6efd;
  color: #fff;
  cursor: pointer;
}

.table-custom-delete-icon {
  background-color: #fff;
  color: #0d6efd;
  font-size: 0.8rem;
  border: 1px solid #0d6efd;
  border-radius: 3px;
  justify-content: center;
  padding: 0.15rem;
  padding-bottom: 0.05rem;
  margin-left: 10px;
}

.table-custom-delete-icon:hover {
  background-color: #0d6efd;
  color: #fff;
  cursor: pointer;
}

.table-custom-send-icon {
  background-color: #fff;
  color: #0d6efd;
  border: 1px solid #0d6efd;
  border-radius: 4px;
  justify-content: center;
  padding: 0.2rem;
  font-size: 0.8rem;
  margin-left: 8px;
}

.table-custom-send-icon:hover {
  background-color: #0d6efd;
  color: #fff;
  cursor: pointer;
}


.table-custom-repeat-icon {
  background-color: #fff;
  color: #0d6efd;
  border: 1px solid #0d6efd;
  border-radius: 4px;
  justify-content: center;
  padding: 0.2rem;
  font-size: 0.8rem;
}

.table-custom-repeat-icon:hover {
  background-color: #0d6efd;
  color: #fff;
  cursor: pointer;
}

.custom-modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  background-color: #fff;
  border-radius: 50%;
  color: #a4a1a1;
  border: 1px solid #a4a1a1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
}

.custom-modal-close-btn:hover {
  border: 1px solid #007bff;
  color: #007bff;
  /* Light green halo effect */
  transform: scale(1.1);
  /* Slight enlargement for emphasis */
}

.feature-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 25px;
}

.feature-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.feature-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.5s;
  border-radius: 20px;
}

.feature-slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 6px;
  bottom: 5px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.feature-slider {
  background-color: #007bff;
}

input:checked+.feature-slider:before {
  transform: translateX(14px);
}

.branding-billing-address {
  border: 1px solid #ccc;
  /* Default border */
  border-radius: 3px;
  /* Rounded corners */
  height: 80px;
  width: 100%;
  /* Adjust the height as needed */
  font-size: 16px;
  /* Optional */
  padding-left: 4px;
  transition: border-color 0.3s ease;
  /* Smooth border color transition */
  cursor: pointer;
  resize: none;
}

.branding-billing-address:focus {
  border-color: #007bff;
  /* Blue border on hover and focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  /* Add shadow */
  outline: none;
  /* Remove default focus outline if any */
}

.branding-billing-address:hover {
  border-color: #007bff;
  /* Blue border on hover */
}

.branding-billing-address:disabled {
  background-color: #f5f5f5;
  /* Light background for disabled state */
  cursor: not-allowed;
  /* Change cursor to indicate disabled state */
}

.branding-billing-address:disabled:hover {
  border-color: #ccc;
  /* Keep the default border when disabled */
}

.app-branding-title {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  background: linear-gradient(35deg, #818eff, #ff6e3e);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  /* Ensure the gradient covers only the text */
}

.app-branding-button {
  background: linear-gradient(135deg, #818eff, #ff6e3e);
  color: #fff;
  border: none;
  padding: 0.55rem 2rem;
  border-radius: 25px;
  text-decoration: none;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s ease, transform 0.3s ease;
  margin-bottom: 3.5rem;
}

.app-branding-button:disabled {
  background: #db9999;
  /* Change background color */
  cursor: not-allowed !important;
  /* Change cursor to indicate it's disabled */
  opacity: 0.7;
  /* Make it appear faded */
  color: #fff;
}


.app-branding-button:hover {
  background: linear-gradient(135deg, #ff6e3e, #818eff);
  color: #fff;
}

/* Tabs CSS */

.tabs-header .nav-tabs .nav-link {
  color: #6c757d;
  /* Default text color */
  border: 1px solid #ddd;
  /* Add border around each tab */

  background-color: #f8f9fa;
  /* Light background for tabs */
  transition: background-color 0.3s, color 0.3s;
  padding: 6px 12px;
  /* Adjust padding for compact height */
  font-size: 0.875rem;
  /* Smaller font size */
  /* margin-bottom: -16px; */
}

.tabs-header .nav-tabs .nav-link:hover {
  color: #0056b3;
  background-color: #e9ecef;
  /* Slightly darker on hover */
  border-color: #ccc;
  /* Darker border on hover */
}

.tabs-header .nav-tabs .nav-link.active {
  color: #fff;
  /* Active tab text color */
  background-color: #007bff;
  /* Background color for selected tab */
  border-color: #007bff;
  /* Border color for active tab */
}

.tabs-header .nav-tabs {
  margin-bottom: 0;
  /* Optional: Reduce bottom margin between tabs and content */
}

/* Custom Check box */

/* Style for the custom checkbox container */
.custom-checkbox {
  position: relative;
  display: inline-block;
}

/* Style the checkbox itself */
.custom-checkbox input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  width: 10px;
  height: 10px;
  cursor: pointer;
}

/* Style the visible checkbox label */
.custom-checkbox label {
  position: relative;
  display: block;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.1s ease;
}

/* Checked state style */
.custom-checkbox input[type="checkbox"]:checked+label {
  background-color: #007bff;
  /* Green background when checked */
  border-color: #007bff;
}

/* Add a checkmark to the checkbox when checked */
.custom-checkbox input[type="checkbox"]:checked+label::after {
  content: "✔";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: #fff;
}

/* Hover state for the label */
.custom-checkbox label:hover {
  border-color: #007bff;
}

/* Custom select box css for chart section start  */
/* General styles for the select dropdown */
.custom-chart-dropdown {
  width: 150px;
  /* Set a fixed width for the dropdown */
  padding: 4px;
  /* Add padding for better aesthetics */
  font-size: 13px;
  /* Set the font size */
  border-radius: 5px;
  /* Rounded corners for a modern look */
  border: 1px solid #ccc;
  /* Light border color */
  background-color: #f9f9f9;
  /* Light background color */
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* Soft shadow for depth */
  transition: all 0.3s ease;
  /* Smooth transition for hover and focus states */
}

/* Hover effect */
.custom-chart-dropdown:hover {
  border: 1px solid #007bff;
  cursor: pointer;
}

/* Focus effect */
.custom-chart-dropdown:focus {
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.3);
  /* Focused shadow with stronger intensity */
  cursor: pointer;
}

/* Custom select box css for chart section end  */

/* For testing css start*/
.testing-custom-chart-dropdown {
  width: 50px;
  /* Set a fixed width for the dropdown */
  padding: 4px;
  /* Add padding for better aesthetics */
  font-size: 13px;
  /* Set the font size */
  border-radius: 5px;
  /* Rounded corners for a modern look */
  border: 1px solid #ccc;
  /* Light border color */
  background-color: #f9f9f9;
  /* Light background color */
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* Soft shadow for depth */
  transition: all 0.3s ease;
  /* Smooth transition for hover and focus states */
}

/* Hover effect */
.testing-custom-chart-dropdown:hover {
  border: 1px solid #007bff;
  cursor: pointer;
}

/* Focus effect */
.testing-custom-chart-dropdown:focus {
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.3);
  /* Focused shadow with stronger intensity */
  cursor: pointer;
}

/* For testing css end*/






/* Custom CSS for the filter modal start */
/* 
.filter-custom-input {
  flex: 1;
  border: 1px solid #ccc;
  outline: none;
  font-size: 12px;
  padding: 4px;
  min-width: 50px;
  transition: border-color 0.3s ease;
}

.filter-custom-input:hover {
  border: 1px solid #007bff;
}

.filter-custom-input:focus {
  border-color: #004d40;
} */

/* Custom CSS for the filter modal end */