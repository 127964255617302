.why-us-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    min-height: 50vh;
    /* Adjusted container height */
}

.why-us-card {
    display: flex;
    align-items: flex-start;
    /* Align items at the top */
    width: 100%;
    max-width: 1200px;
    background: linear-gradient(to right, #e0f7fa, #ffffff);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    overflow: hidden;
    margin: 0 1rem;
    padding: 1.5rem;
    /* Reduced padding */
    max-height: 500px;
    /* Decreased card height */
    box-sizing: border-box;
    /* Include padding in height calculation */
}

.why-us-image {
    width: 40%;
    /* Reduced image width */
    height: auto;
    border-radius: 12px;
    overflow: hidden;
}

.why-us-image img {
    width: 100%;
    height: auto;
    /* Maintain aspect ratio */
    object-fit: cover;
    border-radius: inherit;
}

.why-us-content {
    width: 70%;
    /* Adjust width to balance with the smaller image */
    padding: 1.5rem;
    text-align: left;
    overflow: hidden;
    /* Hide any overflow content */
}

.why-us-content h2 {
    font-size: 1.8rem;
    /* Slightly smaller font size */
    color: #333;
    margin: 0;
}

.why-us-content p {
    font-size: 0.9rem;
    /* Slightly smaller font size */
    color: #555;
    line-height: 1.6;
    margin: 0 0 1rem 0;
    /* Add space at the bottom */
}

.why-us-content .feature {
    margin-bottom: 1.5rem;
    padding: 1rem 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .why-us-card {
        flex-direction: column;
        padding: 1rem;
        max-height: none;
        /* Remove max-height for mobile */
    }

    .why-us-image {
        width: 100%;
        border-radius: 12px;
    }

    .why-us-content {
        width: 100%;
        text-align: center;
    }
}