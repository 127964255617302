.not-found {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #818eff, #ff6e3e);
    color: #333;
    text-align: center;
}

.content {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 3rem;
    border-radius: 12px;
    max-width: 600px;
    width: 90%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.custom-404 {
    font-size: 8rem;
    /* Large font size */
    font-weight: bold;
    /* Make the text bold */
    color: #ff5757;
    /* Bright color for visibility */
    text-align: center;
    /* Center align the text */
    letter-spacing: 5px;
    /* Add spacing between the characters */
    background: linear-gradient(135deg, #818eff, #ff6e3e);
    background-clip: text;
    color: transparent;
}

.error-message {
    font-size: 1.5rem;
    color: #555;
    /* Softer color for the message */
    text-align: center;
    margin-top: 1rem;
}

.custom-404-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40vh;
    /* Make sure the 404 stays centered vertically */
    background-color: #f4f4f4;
    /* Light background to contrast the 404 text */
}



@media (max-width: 600px) {
    h1 {
        font-size: 4rem;
    }

    h2 {
        font-size: 1.5rem;
    }

    p {
        font-size: 0.9rem;
    }
}