* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
}

.under-construction {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #818eff, #ff6e3e);
    color: #fff;
    text-align: center;
}

.container {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 2rem;
    border-radius: 15px;
    max-width: 650px;
    width: 90%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

p {
    font-size: 1.1rem;
    margin: 1rem 0;
}

.countdown {
    display: flex;
    justify-content: space-around;
    margin-top: 1.5rem;
}

.time-box {
    text-align: center;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 1rem;
    width: 70px;
}

.time-box span {
    display: block;
    font-size: 1.5rem;
    font-weight: bold;
    color: #fffa;
}

.time-box small {
    font-size: 0.8rem;
    color: #fff9;
}

@media (max-width: 600px) {
    h1 {
        font-size: 2rem;
    }

    .time-box {
        padding: 0.5rem;
        width: 60px;
    }
}