.about-us-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
    min-height: 80vh;
}

/* About Us Card */
.about-us-card {
    display: flex;
    align-items: center;
    /* Align items at the top */
    width: 100%;
    max-width: 1200px;
    background: linear-gradient(to right, #e0f7fa, #ffffff);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    overflow: hidden;
    margin: 0 1rem;
    padding: 1.5rem;
    /* Reduced padding */
    max-height: 500px;
    /* Decreased card height */
    box-sizing: border-box;
    /* Include padding in height calculation */
}

/* Image Container */
.about-us-image {
    width: 35%;
    height: auto;
    overflow: hidden;
    border-radius: 12px;
}

.about-us-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

/* Title */
.about-us-title {
    font-size: 2rem;
    font-weight: 800;
    color: #333;
    background: linear-gradient(135deg, rgba(128, 0, 128, 0.6), rgba(27, 154, 238, 0.8), rgba(128, 0, 128, 0.6));
    background-clip: text;
    color: transparent;
    margin-bottom: 1rem;
}

/* Content Container */
.about-us-content {
    width: 60%;
    padding: 1.5rem 2rem;
    text-align: left;
}

.about-us-content p {
    font-size: 0.95rem;
    color: #555;
    line-height: 1.6;
    margin: 0;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .about-us-card {
        flex-direction: column;
        padding: 1rem;
    }

    .about-us-image {
        width: 100%;
        border-radius: 12px 12px 0 0;
    }

    .about-us-title {
        font-size: 1.8rem;
        text-align: center;
        margin: 1rem 0;
    }

    .about-us-content {
        width: 100%;
        padding: 1rem;
    }

    .about-us-content p {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .about-us-card {
        padding: 0.5rem;
    }

    .about-us-title {
        font-size: 1.5rem;
        margin-top: 0.5rem;
    }

    .about-us-content {
        padding: 0.5rem;
    }

    .about-us-content p {
        font-size: 0.85rem;
    }
}