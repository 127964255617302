/* General styles for the light theme navigation bar */
.navbar {
    display: flex;
    align-items: center;
    /* Centers items vertically */
    justify-content: space-between;
    background: radial-gradient(circle at left, #e0f7fa, #b3e5fc, #81d4fa);
    padding: 0.5rem 1rem;
    /* Adjust padding to reduce height */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 1000;
    height: 60px;
    /* Set a fixed height if desired */
}

.logo-container {
    display: flex;
    align-items: center;
    /* Centers the logo vertically */
}

.logo {
    width: 120px;
    /* Adjust size of the logo as needed */
    height: auto;
}

/* Transparent icon in the navbar */
.navbar-icon {
    background: transparent;
    /* Semi-transparent background */
    border: none;
    /* Remove border */
    padding: 0.5rem;
    /* Adjust padding as needed */
    /* Make the icon circular */
    cursor: pointer;
    /* Change cursor to pointer on hover */
    transition: background 0.3s ease, transform 0.3s ease;
    /* Smooth hover effect */
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Icon hover effect */
.navbar-icon:hover {

    transform: translateY(-5px);
    /* Enlarge the icon on hover */
}

/* Icon size */
.navbar-icon i {
    font-size: 2.2rem;
    /* Adjust the size of the icon */
    color: #000000;
    /* Icon color */
}

.logonav {
    width: 120px;
    /* Adjust size of the logo as needed */
    height: auto;
}

.nav-links {
    display: flex;
    align-items: center;
    /* Centers nav items vertically */
    list-style: none;
    margin: 0;
    /* Reset margin for better alignment */
}

.nav-links li {
    margin: 0 1rem;
    transition: transform 0.3s ease;
}

.login-btn {
    padding: 0.3rem 0.8rem;
    /* Adjust button size */
    font-size: 0.9rem;
}

.nav-links a {
    color: #333;
    /* Dark color for the text */
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s ease;
}

.nav-links a:hover {
    color: #1e90ff;
    /* Hover color - brighter blue */
}

.login-btn {
    background: linear-gradient(105deg, #818eff, #ff6e3e);
    color: #fff;
    border: none;
    padding: 0.3rem 0.8rem;
    /* Decreased padding for a smaller button */
    font-size: 0.9rem;
    /* Slightly smaller font size */
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: inline-block;
    /* Ensures button scales correctly */
}

.login-btn:hover {
    background: linear-gradient(105deg, #ff6e3e, #818eff);
    transform: translateY(-3px);
    /* Slightly lift the button on hover */
}

.login-btn:disabled {
    background: #ccc;
    cursor: not-allowed;
    opacity: 0.6;
    transform: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .login-btn {
        padding: 0.2rem 0.6rem;
        /* Further reduce padding on smaller screens */
        font-size: 0.8rem;
        /* Smaller font size for mobile */
    }
}

@media (max-width: 480px) {
    .login-btn {
        padding: 0.15rem 0.5rem;
        /* Even smaller padding for extra-small screens */
        font-size: 0.75rem;
        /* Slightly smaller font */
    }
}

/* Sidebar styles */
.sidebar {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 250px;
    background: linear-gradient(290deg, #90e1ee, #f0f8ff);
    /* Match navbar gradient */
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    transform: translateX(100%);
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.sidebar.open {
    transform: translateX(0);
}

.sidebar-close {
    background: transparent;
    border: none;
    font-size: 2rem;
    color: #333;
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.sidebar-links {
    list-style: none;
    padding: 0;
}

.sidebar-links li {
    margin: 1rem 0;
}

.sidebar-links a {
    color: #333;
    /* Dark color for the text */
    text-decoration: none;
    font-size: 1.5rem;
}

.sidebar-links a:hover {
    color: #1e90ff;
    /* Hover color - brighter blue */
}

/* Responsive styles */
.mobile-menu-icon {
    display: none;
    font-size: 2rem;
    cursor: pointer;
}

@media (max-width: 768px) {
    .nav-links {
        display: none;
    }

    .mobile-menu-icon {
        display: block;
    }
}