/*--------------------------------------------------------------
  # Header Nav
  --------------------------------------------------------------*/
.dashboard-nav ul {
    list-style: none;
}

.dashboard-nav>ul {
    margin: 0;
    padding: 0;
}

.dashboard-nav .nav-icon {
    font-size: 22px;
    color: #012970;
    margin-right: 25px;
    position: relative;
}

.dashboard-nav .nav-profile {
    color: #012970;
}

.dashboard-nav .nav-profile img {
    max-height: 36px;
}

.dashboard-nav .nav-profile span {
    font-size: 14px;
    font-weight: 600;
}

.dashboard-nav .badge-number {
    position: absolute;
    inset: -2px -5px auto auto;
    font-weight: normal;
    font-size: 12px;
    padding: 3px 6px;
}

.dashboard-nav .notifications {
    inset: 8px -15px auto auto !important;
}

.dashboard-nav .notifications .notification-item {
    display: flex;
    align-items: center;
    padding: 15px 10px;
    transition: 0.3s;
}

.dashboard-nav .notifications .notification-item i {
    margin: 0 20px 0 10px;
    font-size: 24px;
}

.dashboard-nav .notifications .notification-item h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}

.dashboard-nav .notifications .notification-item p {
    font-size: 13px;
    margin-bottom: 3px;
    color: #919191;
}

.dashboard-nav .notifications .notification-item:hover {
    background-color: #f6f9ff;
}

.dashboard-nav .messages {
    inset: 8px -15px auto auto !important;
}

.dashboard-nav .messages .message-item {
    padding: 15px 10px;
    transition: 0.3s;
}

.dashboard-nav .messages .message-item a {
    display: flex;
}

.dashboard-nav .messages .message-item img {
    margin: 0 20px 0 10px;
    max-height: 40px;
}

.dashboard-nav .messages .message-item h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #444444;
}

.dashboard-nav .messages .message-item p {
    font-size: 13px;
    margin-bottom: 3px;
    color: #919191;
}

.dashboard-nav .messages .message-item:hover {
    background-color: #f6f9ff;
}

.dashboard-nav .profile {
    min-width: 240px;
    padding-bottom: 0;
    top: 8px !important;
}

.dashboard-nav .profile .dropdown-header h6 {
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 600;
    color: #444444;
}

.dashboard-nav .profile .dropdown-header span {
    font-size: 14px;
}

.dashboard-nav .profile .dropdown-item {
    font-size: 14px;
    padding: 10px 15px;
    transition: 0.3s;
}

.dashboard-nav .profile .dropdown-item i {
    margin-right: 10px;
    font-size: 18px;
    line-height: 0;
}

.dashboard-nav .profile .dropdown-item:hover {
    background-color: #f6f9ff;
}