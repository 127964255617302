.alert-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
    /* This adds space between stacked alerts */
}


/* Common styles for the alert box */
.alert-box {
    position: fixed;
    top: 20px;
    /* Positioning the alert at the top */
    right: 20px;
    /* Positioning the alert to the right */
    min-width: 250px;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    height: auto;
    /* Adding padding to accommodate the close button */
    border-radius: 5px;
    color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation: slideIn 0.5s ease-in-out;
}

/* Success alert styles */
.alert-box.success {
    background-color: #4caf50;
}

/* Failure alert styles */
.alert-box.failure {
    background-color: #f3594e;
}

/* Warning alert styles */
.alert-box.warning {
    background-color: #ff9800;
}

/* Close button styles */
.close-btn {
    position: absolute;
    /* Position the close button absolutely */
    top: 5px;
    /* Adjust to place it at the top corner */
    right: 10px;
    /* Adjust to place it at the right corner */
    background: none;
    border: none;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}

/* Animation for sliding the alert in */
@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(100%);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}