/* Modal Styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    /* Adjust opacity for transparency */
    z-index: 9999;
}

/* Pop-up Window Styles */
.loading-content {
    display: flex;
    flex-direction: row;
    /* Aligns logo and text horizontally */
    justify-content: flex-start;
    /* Aligns content to the left */
    align-items: center;
    /* Centers items vertically */
    width: 500px;
    /* Adjust width to ensure text stays on one line */
    padding: 30px;
    border-radius: 15px;
    background: linear-gradient(135deg, #f3f9f6, #e6f7ff);
    /* Light gradient background */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* .loading-content {
    display: flex;
    flex-direction: row; 
    justify-content: center;
    align-items: center;
    width: 400px;
    padding: 30px;
    border-radius: 15px;
    background: linear-gradient(135deg, #f3f9f6, #e6f7ff);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
} */

/* Loading Text Styles */
.loading-text {
    flex-basis: 60%;
    /* 60% width for the text */
    font-size: 18px;
    margin-left: 50px;
    /* Adds space between logo and text */
    color: #333;
    text-align: left;
}

/* Rotation Animation for Up-Down and Left-Right */
@keyframes rotateUpDown {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotateX(180deg);
    }

    50% {
        transform: rotate(360deg);
    }

    75% {
        transform: rotateY(180deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

/* Rotation Animation */
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Spinner Logo Styles */
/* .spinnerlogo {
    width: 60px;
    height: 60px;
    animation: rotateUpDown 5s infinite ease-in-out;
    transform-style: preserve-3d;
} */

.spinnerlogo {
    width: 60px;
    /* Adjust size for the spinner */
    height: 60px;
    animation: rotate 5s linear infinite;
    /* Rotates the logo continuously */
}

/* Center both logo and text within the modal */