/* General styles for the service section */

.service-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
    min-height: 60vh;
    gap: 2rem;
}

.service-card {
    /* White background for the card */
    border-radius: 12px;
    /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Shadow for the card */
    overflow: hidden;
    /* Hide overflow to ensure image is contained */
    max-width: 300px;
    /* Limit card width */
    width: 100%;
    position: relative;
    /* Positioning for image pop-up effect */
    cursor: pointer;
    background: linear-gradient(135deg, #ffffff, #e0f7fa);
}

.service-image {
    position: relative;
    overflow: hidden;
}

.service-image img {
    width: 100%;
    height: auto;
    display: block;
    transform: translateY(-20px);
    /* Pop-up effect for the image */
    transition: transform 0.3s ease;
    /* Smooth transition */
}

.service-card:hover .service-image img {
    transform: translateY(-30px);
    /* Enhance pop-up effect on hover */
}

.service-text {
    padding: 1rem;
    text-align: center;
    /* Center align text */
}

.service-text h4 {
    font-size: 1.2rem;
    /* Adjust the size as needed */
    color: #333;
    /* Keep dark color for readability */
    margin-bottom: 0.5rem;
    /* Adjust spacing below */
}

.service-text p {
    font-size: 0.9rem;
    /* Adjust the size as needed */
    color: #666;
    /* Keep lighter color for readability */
    margin-bottom: 0.5rem;
    /* Adjust spacing below */
}

.service-btn {
    background: linear-gradient(105deg, #818eff, #ff6e3e);
    color: #fff;
    border: none;
    padding: 0.5rem 2rem;
    /* Increased padding for better alignment */
    border-radius: 25px;
    text-decoration: none;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.3s ease, transform 0.3s ease;
}

.service-btn:hover {
    background: linear-gradient(105deg, #ff6e3e, #818eff);
    transform: scale(1.05);
    text-decoration: none;
}

/* Responsive design for smaller devices */
@media (max-width: 768px) {
    .service-container {
        flex-direction: column;
        /* Stack cards vertically */
        align-items: center;
    }

    .service-card {
        max-width: 80%;
        /* Full width for cards on small screens */
        padding: 0.5rem;
        /* Reduced padding for small screens */
        /* White background for the card */
        border-radius: 12px;
        /* Rounded corners */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .service-image {
        width: 100%;
        border-radius: 12px;
        height: auto;
        display: block;
        position: relative;
        overflow: hidden;
        transform: translateY(-20px);
        /* Pop-up effect for the image */
        transition: transform 0.3s ease;
    }


    .service-text {
        padding: 0.5rem;
        /* Reduced padding */
    }

    .service-text h3 {
        font-size: 1.5rem;
        /* Smaller title font size */
    }

    .service-text p {
        font-size: 1rem;
        /* Smaller description font size */
    }

    .service-btn {
        padding: 0.8rem 1.2rem;
        /* Smaller button padding */
        font-size: 1rem;
        /* Smaller button font size */
    }
}