/*--------------------------------------------------------------
# Logo
--------------------------------------------------------------*/

.dashboard-logo {
  line-height: 1;
}

@media (min-width: 1200px) {
  .dashboard-logo {
    width: 280px;
  }
}

.dashboard-logo-small img {
  max-height: 50px;
  margin-right: 6px;
  margin-left: 40px;
  /* Add left margin to shift the image right */
}

.dashboard-logo img {
  max-height: 40px;
  margin-right: 6px;
  margin-left: 40px;
  /* Add left margin to shift the image right */
}

.dashboard-logo span {
  font-size: 26px;
  font-weight: 700;
  color: #012970;
  font-family: 'Nunito', sans-serif;
}