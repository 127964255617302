/*--------------------------------------------------------------
# Page Title
--------------------------------------------------------------*/
.pagetitle {
  margin-bottom: 10px;
}


.pagetitle h1 {
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 600;
  background: linear-gradient(135deg, #818eff, #ff6e3e);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  /* Ensure the gradient covers only the text */
}




/* Breadcrumbs */
.breadcrumb {
  font-size: 14px;
  font-family: 'Nunito', sans-serif;
  color: #899bbd;
  font-weight: 600;
}

.breadcrumb a {
  color: #899bbd;
  transition: 0.3s;
}

.breadcrumb a:hover {
  color: #51678f;
}

.breadcrumb .breadcrumb-item::before {
  color: #899bbd;
}

.breadcrumb .active {
  color: #51678f;
  font-weight: 600;
}